import React from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import { pink } from "@mui/material/colors";
import PlaceIcon from "@mui/icons-material/Place";
import BarChartIcon from "@mui/icons-material/BarChart";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import MenuCard from "../components/MenuCard";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

const outcomes = [
	{
		img: "plos.png",
		title: "Archaeological expansions in tropical South America during the late Holocene: Assessing the role of demic diffusion",
		sub: "https://doi.org/10.1371/journal.pone.0232367",
		text: "To assess the feasibility of demic diffusion as the process behind the archaeological expansions in question, we employ agent-based simulations with demographic parameters derived from the ethnography of tropical forest farmers.",
	},
	{
		img: "tupi2.jpg",
		title: "Reassessing the role of climate change in the Tupi expansion (South America, 5000–500 BP)",
		sub: "https://doi.org/10.1098/rsif.2021.0499",
		text: "We employ equation-based and cellular automaton models, simulating demic-diffusion processes under two scenarios: a null model in which all cells can be settled, and an alternative model in which non-forested cells cannot be settled.",
	},
	{
		img: "expand.png",
		title: "ExPaND: Simulating demic expansion of farmers in tropical South America",
		sub: "https://github.com/jgregoriods/expand",
		text: "This is an agent-based model for simulating the demic expansion of tropical forest farmers in late Holocene South America.",
	},
	{
		img: "tupi.jpg",
		title: "Simulating the Tupi expansion in South America",
		sub: "https://github.com/jgregoriods/tupi-expansion",
		text: "This repository contains the R and python code for simulating demic diffusion of tropical forest farmers in lowland South America, namely the Tupi language family, and the role of climate change/forest expansion in shaping their dispersal.",
	},
	{
		img: "disperse.png",
		title: "dispeRse: Simulation of demic diffusion with environmental constraints",
		sub: "https://github.com/jgregoriods/dispeRse",
		text: "The package contains tools for simulating demic-diffusion processes, with particular attention to environmental influences. Growth and migration are modelled as density-dependent processes.",
	},
];

const Home = () => {
	const { pathname, hash, key } = useLocation();

	useEffect(() => {
		const anchor = window.location.hash.split("#")[1];
		if (anchor) {
			const anchorEl = document.getElementById(anchor);
			if (anchorEl) {
				anchorEl.scrollIntoView({
					behavior: "smooth",
				});
			}
		}
	}, [pathname, hash, key]);

	return (
		<React.Fragment>
			<Grid container className="hero-image">
				<Container
					className="hero-title"
					maxWidth="md"
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="h1"
						sx={{
							color: "white",
							textAlign: "center",
							fontFamily: "Roboto Condensed",
						}}
					>
						EXPAND
					</Typography>
					<Typography
						variant="h6"
						sx={{ color: "white", mb: 4, textAlign: "center" }}
					>
						Integrating archaeology, palaeoecology and computer
						modelling to simulate human migrations during climate
						Change in South America
					</Typography>
					<Grid
						container
						spacing={1}
						sx={{
							mb: 6,
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Grid item xs={12} sm={3}>
							<ScrollLink to="about" smooth={true} duration={500}>
								<Button
									size="large"
									variant="outlined"
									color="secondary"
									sx={{
										minWidth: "100%",
										"&:hover": { color: "white" },
									}}
								>
									About
								</Button>
							</ScrollLink>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Button
								component={Link}
								size="large"
								variant="contained"
								to="/database"
								sx={{
									minWidth: "100%",
									"&:hover": { color: "white" },
								}}
							>
								Database
							</Button>
						</Grid>
					</Grid>
				</Container>
			</Grid>

			<Grid container sx={{ m: 0, p: 0 }}>
				{/* About */}
				<Grid container sx={{ backgroundColor: "white", pt: 6, pb: 6 }}>
					<Container>
						<section id="about" className="anchor-id" />
						<Grid
							item
							xs={12}
							sx={{ display: "flex", justifyContent: "center" }}
						>
							<Typography
								variant="h3"
								sx={{ fontWeight: "bold", mb: 4 }}
							>
								About the project
							</Typography>
						</Grid>
						<Grid container spacing={2}>
							<Grid
								item
								xs={12}
								md={5}
								display={{ xs: "none", md: "flex" }}
								sx={{
									maxHeight: "400px",
									justifyContent: "center",
								}}
							>
								<img
									src={require("../img/languages.png")}
									height="100%"
								/>
							</Grid>
							<Grid item xs={12} md={7}>
								<Typography variant="body1" paragraph={true}>
									The expansion of farmers and their languages
									was a key process in shaping cultural
									geographies across the globe. Many human
									expansions coincided with periods of climate
									change, which offered both opportunities and
									constraints for migrations.
								</Typography>
								<Typography variant="body1" paragraph={true}>
									In South America, the most widespread
									language families, such as Arawak, Tupi and
									Karib, have been linked to the expansion of
									polyculture agroforestry out of Amazonia.
									Their migrations occurred in a period of
									increasing precipitation and expansion of
									forests. Thus, South America offers a unique
									case study to test the role of climate
									change in human migrations.
								</Typography>
								<Box
									display={{ xs: "flex", md: "none" }}
									sx={{ justifyContent: "center" }}
								>
									<img
										src={require("../img/languages.png")}
										width="40%"
									/>
								</Box>
								<Typography variant="body1" paragraph={true}>
									The project compiled archaeological and
									palaeoecological data, which was used to
									model cultural expansions and reconstruct
									land cover changes. The results allowed us
									to assess of the the timing, pace and routes
									of expansion of archaeological cultures in
									lowland South America. Using agent-based
									modelling, the project simulated scenarios
									of human migrations with a dynamic
									environmental background to evaluate the
									role of climate change in human dispersals
									across South America.
								</Typography>
							</Grid>
						</Grid>
					</Container>
				</Grid>

				{/* People */}
				<Container sx={{ pt: 6, pb: 6 }}>
					<section id="people" className="anchor-id" />
					<Grid
						item
						xs={12}
						sx={{ display: "flex", justifyContent: "center" }}
					>
						<Typography
							variant="h3"
							sx={{ fontWeight: "bold", mb: 4 }}
						>
							The people
						</Typography>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs={12} md={6}>
							<Avatar
								src={require("../img/jonas.jpeg")}
								sx={{
									width: "7em",
									height: "7em",
									ml: "auto",
									mr: "auto",
									filter: "saturate(0)",
								}}
							/>
							<Typography
								variant="h5"
								sx={{
									fontWeight: "bold",
									textAlign: "center",
									mb: 1,
									mt: 1,
								}}
							>
								Jonas Gregorio de Souza&nbsp;
								<MuiLink
									href="https://orcid.org/0000-0001-6032-4443"
									target="_blank"
									sx={{
										textDecoration: "none",
										verticalAlign: "middle",
									}}
								>
									<img
										src={require("../img/orcid.png")}
										height={25}
									/>
								</MuiLink>
							</Typography>
							<Typography variant="body1" paragraph={true}>
								Jonas develops computational models to analyze
								settlement patterns, population dynamics, and
								cultural interactions. He was the leading
								researcher in the ExPaND project, which
								simulated human migrations in South America
								under varying climate change scenarios.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<Avatar
								src={require("../img/madella.jpeg")}
								sx={{
									width: "7em",
									height: "7em",
									ml: "auto",
									mr: "auto",
								}}
							/>
							<Typography
								variant="h5"
								sx={{
									fontWeight: "bold",
									textAlign: "center",
									mb: 1,
									mt: 1,
								}}
							>
								Marco Madella&nbsp;
								<MuiLink
									href="https://orcid.org/0000-0002-9324-1545"
									target="_blank"
									sx={{
										textDecoration: "none",
										verticalAlign: "middle",
									}}
								>
									<img
										src={require("../img/orcid.png")}
										height={25}
									/>
								</MuiLink>
							</Typography>
							<Typography variant="body1" paragraph={true}>
								Marco is an archaoeobotanist and environmental
								archaeologists whose research explores
								trajectories of social change and
								co-evolutionary processes by modeling and
								simulation. He coordinated the CONSOLIDER
								project SimulPast, focused on modelling and
								simulation of ancient societies and their
								relationship with environmental transformations.
							</Typography>
						</Grid>
					</Grid>
				</Container>

				{/* Database */}
				<Grid container sx={{ backgroundColor: "white", pt: 6, pb: 6 }}>
					<a id="database" className="anchor-id" />
					<Container>
						<Grid
							item
							xs={12}
							sx={{ display: "flex", justifyContent: "center" }}
						>
							<Typography
								variant="h3"
								sx={{ fontWeight: "bold", mb: 4 }}
							>
								The database
							</Typography>
						</Grid>
						<Typography variant="body1" paragraph={true}>
							Until recently, most information on lowland South
							American archaeological sites and dates was
							unpublished or dispersed. An important part of the
							ExPaND project was the compilation of all available
							dates, coordinates, and cultural information for
							archaeological sites in tropical South America dated
							at least 5000 BP. This database is available to the
							scientific community and general public.
						</Typography>
						<Grid
							container
							spacing={4}
							sx={{ pl: { sm: 12 }, pr: { sm: 12 } }}
						>
							<Grid item xs={12} sm={6}>
								<AnimationOnScroll
									animateIn="animate__fadeIn"
									duration={0.5}
									animateOnce={true}
								>
									<Card
										variant="contained"
										sx={{ border: "none" }}
									>
										<CardContent
											sx={{ textAlign: "center" }}
										>
											<PlaceIcon
												sx={{
													fontSize: 60,
													color: pink[400],
												}}
											/>
											<Typography
												variant="h1"
												sx={{ fontWeight: "bold" }}
											>
												1023
											</Typography>
											<Typography variant="h4">
												Archaeological sites
											</Typography>
										</CardContent>
									</Card>
								</AnimationOnScroll>
							</Grid>
							<Grid item xs={12} sm={6}>
								<AnimationOnScroll
									animateIn="animate__fadeIn"
									duration={0.5}
									animateOnce={true}
								>
									<Card
										variant="contained"
										sx={{ border: "none" }}
									>
										<CardContent
											sx={{ textAlign: "center" }}
										>
											<BarChartIcon
												sx={{
													fontSize: 60,
													color: pink[400],
												}}
											/>
											<Typography
												variant="h1"
												sx={{ fontWeight: "bold" }}
											>
												2762
											</Typography>
											<Typography variant="h4">
												Radiocarbon dates
											</Typography>
										</CardContent>
									</Card>
								</AnimationOnScroll>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								mt: 2,
							}}
						>
							<AnimationOnScroll
								animateIn="animate__fadeIn"
								duration={0.5}
								animateOnce={true}
							>
								<Button
									size="large"
									sx={{
										minWidth: { xs: "90vw", sm: "16vw" },
									}}
									variant="contained"
									component={Link}
									to="/database"
								>
									Search the database
								</Button>
							</AnimationOnScroll>
						</Grid>
					</Container>
				</Grid>

				<Grid container sx={{ pt: 6, pb: 6 }}>
					<a id="outcomes" className="anchor-id" />
					<Container>
						<Grid
							item
							xs={12}
							sx={{ display: "flex", justifyContent: "center" }}
						>
							<Typography
								variant="h3"
								sx={{ fontWeight: "bold", mb: 4 }}
							>
								Outcomes
							</Typography>
						</Grid>

						<Grid container spacing={4}>
							{outcomes.map((item) => (
								<MenuCard
									key={item.title}
									img={item.img}
									title={item.title}
									sub={item.sub}
									text={item.text}
								/>
							))}
						</Grid>
						<Grid
							item
							sx={{
								display: "flex",
								justifyContent: "center",
								mt: 2,
							}}
						></Grid>
					</Container>
				</Grid>

				<Grid container sx={{ backgroundColor: "white", pt: 6, pb: 6 }}>
					<Container>
						<Grid container spacing={4}>
							<Grid
								item
								xs={12}
								sm={6}
								sx={{
									filter: "saturate(0) contrast(2)",
									verticalAlign: "middle",
								}}
							>
								<Typography sx={{ mb: 1 }}>
									Funded by the Marie Skłodowska-Curie Actions
								</Typography>
								<img
									src={require("../img/msca.png")}
									height="50px"
								/>
								&nbsp;
								<img
									src={require("../img/upf.png")}
									height="50px"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography sx={{ mb: 1 }}>
									Built and designed by Jonas Gregorio
								</Typography>
								<a
									href="https://twitter.com/jonasgdes"
									target="_blank"
									rel="noreferrer noopener"
								>
									<TwitterIcon
										style={{ fontSize: 40, color: "black" }}
									/>
								</a>
								<a
									href="https://www.linkedin.com/in/jgregoriods/"
									target="_blank"
									rel="noreferrer noopener"
								>
									<LinkedInIcon
										style={{ fontSize: 40, color: "black" }}
									/>
								</a>
								<a
									href="https://github.com/jgregoriods"
									target="_blank"
									rel="noreferrer noopener"
								>
									<GitHubIcon
										style={{ fontSize: 40, color: "black" }}
									/>
								</a>
							</Grid>
						</Grid>
					</Container>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default Home;
